import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import swal2 from "sweetalert2";
import { Observable, Subject } from "rxjs";
import { FacturasService } from "src/app/services/service.index";
import * as XLSX from "xlsx";
@Component({
  selector: "app-altafacturasdd",
  templateUrl: "./altafacturasdd.component.html",
  styleUrls: ["./altafacturasdd.component.css"],
})
export class AltafacturasddComponent implements OnInit {
  files = [];
  filesNames = [];
  filespdf = [];
  filesNamespdf = [];
  resulgood = [];
  resulbad = [];
  procesadasbien = 0;
  procesadasmal = 0;
  muestrareporte: boolean;
  myForm = new FormGroup({
    filesPdf: new FormControl(),
    filesXml: new FormControl(),
  });
  constructor(private facturas: FacturasService) {}

  ngOnInit() {
    this.myForm.reset();
    this.files = [];
    this.filesNames = [];
    this.filespdf = [];
    this.filesNamespdf = [];
    this.resulgood = [];
    this.resulbad = [];
    this.procesadasbien = 0;
    this.procesadasmal = 0;
    this.muestrareporte = false;
  }

  formatoFechaexcel(fecha: string) {
    //  fecha = '06/07/2021';
    const a = new Date(fecha);
    // console.log(a);
    a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
    let montha = "" + (a.getMonth() + 1);
    let daya = "" + a.getDate();
    const yeara = a.getFullYear();

    if (montha.length < 2) {
      montha = "0" + montha;
    }
    if (daya.length < 2) {
      daya = "0" + daya;
    }

    const resul = [yeara, montha, daya].join("-");

    return resul;
  }

  reset() {
    window.location.reload();
  }

  borraPdf(item) {
    for (var i = 0; i < this.myForm.value.filesPdf.length; i++) {
      if (this.myForm.value.filesPdf[i].name === item) {
        this.myForm.value.filesPdf.splice(i, 1);
        this.myForm.patchValue({
          filesPdf: this.myForm.value.filesPdf,
        });
      }
    }
    for (var i = 0; i < this.filesNamespdf.length; i++) {
      if (this.filesNamespdf[i] === item) {
        this.filesNamespdf.splice(i, 1);
      }
    }
  }

  borraXml(item) {
    for (var i = 0; i < this.myForm.value.filesXml.length; i++) {
      if (this.myForm.value.filesXml[i].name === item) {
        this.myForm.value.filesXml.splice(i, 1);
        this.myForm.patchValue({
          filesXml: this.myForm.value.filesXml,
        });
      }
    }
    for (var i = 0; i < this.filesNames.length; i++) {
      if (this.filesNames[i] === item) {
        this.filesNames.splice(i, 1);
      }
    }
  }
  dragEnter(id: string) {
    document.getElementById(id).style.border = "dashed 2px blue";
  }
  dragLeave(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }
  drop(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }
  async onFileChange(event) {
    for await (let num of event) {
      if (num.type.toLowerCase().includes("pdf")) {
        if (this.filespdf.length === 0) {
          this.filespdf.push(num);
          this.filesNamespdf.push(num.name);
        } else {
          if (!this.filesNamespdf.includes(num.name)) {
            this.filespdf.push(num);
            this.filesNamespdf.push(num.name);
          }
        }
        this.myForm.patchValue({
          filesPdf: this.filespdf,
        });
        this.filesNamespdf.sort();
      }
      if (num.type.toLowerCase().includes("xml")) {
        if (this.files.length === 0) {
          this.files.push(num);
          this.filesNames.push(num.name);
        } else {
          if (!this.filesNames.includes(num.name)) {
            this.files.push(num);
            this.filesNames.push(num.name);
          }
        }
        this.myForm.patchValue({
          filesXml: this.files,
        });
        this.myForm.value.filesXml.sort();
      }
    }
  }

  async procesarfacturas() {
    swal2.fire("Procesando", "Espere por favor", "info");
    swal2.showLoading();
    this.procesadasbien = 0;
    this.procesadasmal = 0;
    let namespdf = [];
    let namesxml = [];
    if (
      this.myForm.value.filesPdf === null ||
      this.myForm.value.filesXml === null
    ) {
      swal2.fire("Debe arrarstrar al menos un pdf y un xml", "", "info");
      return;
    } else if (
      this.myForm.value.filesPdf.length !== this.myForm.value.filesXml.length
    ) {
      swal2.fire(
        "La cantidad de archivos pdf y xml deben ser iguales",
        "",
        "info"
      );
      return;
    } else {
      for await (let pdf of this.myForm.value.filesPdf) {
        namespdf.push(pdf.name.substring(0, pdf.name.length - 4));
      }
      for await (let xml of this.myForm.value.filesXml) {
        namesxml.push(xml.name.substring(0, xml.name.length - 4));
      }
      namespdf.sort();
      namesxml.sort();
      let isEqual = JSON.stringify(namespdf) === JSON.stringify(namesxml);
      if (!isEqual) {
        swal2.fire("Los nombres de los PDF y XML deben coincidir", "", "info");
        return;
      }
    }
    let f = new Date();
    let fechaactual = this.formatoFechaexcel(f.toString());
    for await (let xml of this.myForm.value.filesXml) {
      let jsonxml1 = await (await this.readFileXml(xml)).toPromise();
      let jsonxml = await this.xml2json(jsonxml1, "");
      try {
        let data = {
          token: "",
          secret_key: "",
          emitter_rfc:
            JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Emisor"].Rfc,
          receiver_rfc:
            JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Receptor"].Rfc,
          invoice_folio: this.getValues(JSON.parse(jsonxml), "Folio")[0],
          invoice_date: this.formatoFechaexcel(
            this.getValues(JSON.parse(jsonxml), "Fecha")[0]
          ),
          currency: this.getValues(JSON.parse(jsonxml), "Moneda")[0],
          total: this.getValues(JSON.parse(jsonxml), "Total")[0],
          xml: "",
          uuid: this.getValues(JSON.parse(jsonxml), "UUID")[0],
          invoice_serie: this.getValues(JSON.parse(jsonxml), "Serie")[0],
          document_type: this.getValues(
            JSON.parse(jsonxml),
            "TipoDeComprobante"
          )[0],
          pdf: "",
          entry_date: fechaactual,
          status: "PENDIENTE",
        };
        if (data.currency === "MXN") {
          data.currency = "PESOS";
        }
        if (data.document_type === "I") {
          data.document_type = "Ingresos";
        }
        for await (let pdf of this.myForm.value.filesPdf) {
          if (
            pdf.name.substring(0, pdf.name.length - 4) ===
            xml.name.substring(0, xml.name.length - 4)
          ) {
            let respPdf = await this.callupload(pdf);
            let respXml = await this.callupload(xml);
            data.pdf = respPdf.resp.Location;
            data.xml = respXml.resp.Location;
            let response = await this.subefactura(
              data,
              pdf.name.substring(0, pdf.name.length - 4)
            );
            if (response.msg === "OK") {
              this.procesadasbien = this.procesadasbien + 1;
              this.resulgood.push(response);
            } else if (response.msg === "Error") {
              if (response.resp.error.status === 500) {
                this.procesadasmal = this.procesadasmal + 1;
              } else if (response.resp.error.errors[0].includes('Ya existe una factura dada de alta con el uuid')){
                this.procesadasmal = this.procesadasmal + 1;
                this.resulbad.push(response);
              }else {
                this.procesadasmal = this.procesadasmal + 1;
                this.facturas.delteFile(respPdf.resp.key);
                this.facturas.delteFile(respXml.resp.key);
                this.resulbad.push(response);
              }
            }
          }
        }
      } catch (error) {
        this.procesadasmal = this.procesadasmal + 1;
        let resp = {
          error: { errors: ["Xml no tiene el formato correcto"] },
        };
        let respo = {
          status: true,
          resp: resp,
          msg: "Error",
          nombre: xml.name.substring(0, xml.name.length - 4),
        };
        this.resulbad.push(respo);
      }
    }
    this.muestrareporte = true;
    swal2.close();
  }
  async readFileXml(file: File) {
    const sub = new Subject<any>();
    var reader = new FileReader();

    reader.onload = () => {
      var xmlData = reader.result;
      var parser = new DOMParser();
      var doc = parser.parseFromString(xmlData.toString(), "text/xml");
      sub.next(doc);
      sub.complete();
    };

    reader.readAsText(file);
    return sub.asObservable();
  }

  async subefactura(data, name) {
    try {
      const r = await (await this.facturas.guardaFacturaDD(data)).toPromise();
      let resp = {
        status: true,
        resp: r,
        msg: "OK",
        nombre: name,
      };
      return resp;
    } catch (error) {
      let resp = {
        status: false,
        resp: error,
        msg: "Error",
        nombre: name,
      };
      return resp;
    }
  }

  async callupload(file) {
    try {
      const r = await this.facturas.uploadFile(file);
      let resp = {
        status: true,
        resp: r,
        msg: "Archivo subido correctamente",
      };
      return resp;
    } catch (error) {
      let resp = {
        status: false,
        resp: error,
        msg: "Fallo al subir archivo",
      };
      return resp;
    }
  }
  async xml2json(xml, tab) {
    var X = {
      toObj: function (xml) {
        var o = {};
        if (xml.nodeType == 1) {
          // element node ..
          if (xml.attributes.length)
            // element with attributes  ..
            for (var i = 0; i < xml.attributes.length; i++)
              //o["@"+xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue||"").toString();
              o[xml.attributes[i].nodeName] = (
                xml.attributes[i].nodeValue || ""
              ).toString();
          if (xml.firstChild) {
            // element has child nodes ..
            var textChild = 0,
              cdataChild = 0,
              hasElementChild = false;
            for (var n = xml.firstChild; n; n = n.nextSibling) {
              if (n.nodeType == 1) hasElementChild = true;
              else if (n.nodeType == 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/))
                textChild++; // non-whitespace text
              else if (n.nodeType == 4) cdataChild++; // cdata section node
            }
            if (hasElementChild) {
              if (textChild < 2 && cdataChild < 2) {
                // structured element with evtl. a single text or/and cdata node ..
                X.removeWhite(xml);
                for (var n = xml.firstChild; n; n = n.nextSibling) {
                  if (n.nodeType == 3)
                    // text node
                    o["#text"] = X.escape(n.nodeValue);
                  else if (n.nodeType == 4)
                    // cdata node
                    o["#cdata"] = X.escape(n.nodeValue);
                  else if (o[n.nodeName]) {
                    // multiple occurence of element ..
                    if (o[n.nodeName] instanceof Array)
                      o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                    else o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                  } // first occurence of element..
                  else o[n.nodeName] = X.toObj(n);
                }
              } else {
                // mixed content
                if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
                else o["#text"] = X.escape(X.innerXml(xml));
              }
            } else if (textChild) {
              // pure text
              if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
              else o["#text"] = X.escape(X.innerXml(xml));
            } else if (cdataChild) {
              // cdata
              if (cdataChild > 1) o = X.escape(X.innerXml(xml));
              else
                for (var n = xml.firstChild; n; n = n.nextSibling)
                  o["#cdata"] = X.escape(n.nodeValue);
            }
          }
          if (!xml.attributes.length && !xml.firstChild) o = null;
        } else if (xml.nodeType == 9) {
          // document.node
          o = X.toObj(xml.documentElement);
        } else alert("unhandled node type: " + xml.nodeType);
        return o;
      },
      toJson: function (o, name, ind) {
        var json = name ? '"' + name + '"' : "";
        if (o instanceof Array) {
          for (var i = 0, n = o.length; i < n; i++)
            o[i] = X.toJson(o[i], "", ind + "\t");
          json +=
            (name ? ":[" : "[") +
            (o.length > 1
              ? "\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind
              : o.join("")) +
            "]";
        } else if (o == null) json += (name && ":") + "null";
        else if (typeof o == "object") {
          var arr = [];
          for (var m in o) arr[arr.length] = X.toJson(o[m], m, ind + "\t");
          json +=
            (name ? ":{" : "{") +
            (arr.length > 1
              ? "\n" + ind + "\t" + arr.join(",\n" + ind + "\t") + "\n" + ind
              : arr.join("")) +
            "}";
        } else if (typeof o == "string")
          json += (name && ":") + '"' + o.toString() + '"';
        else json += (name && ":") + o.toString();
        return json;
      },
      innerXml: function (node) {
        var s = "";
        if ("innerHTML" in node) s = node.innerHTML;
        else {
          var asXml = function (n) {
            var s = "";
            if (n.nodeType == 1) {
              s += "<" + n.nodeName;
              for (var i = 0; i < n.attributes.length; i++)
                s +=
                  " " +
                  n.attributes[i].nodeName +
                  '="' +
                  (n.attributes[i].nodeValue || "").toString() +
                  '"';
              if (n.firstChild) {
                s += ">";
                for (var c = n.firstChild; c; c = c.nextSibling) s += asXml(c);
                s += "</" + n.nodeName + ">";
              } else s += "/>";
            } else if (n.nodeType == 3) s += n.nodeValue;
            else if (n.nodeType == 4) s += "<![CDATA[" + n.nodeValue + "]]>";
            return s;
          };
          for (var c = node.firstChild; c; c = c.nextSibling) s += asXml(c);
        }
        return s;
      },
      escape: function (txt) {
        return txt
          .replace(/[\\]/g, "\\\\")
          .replace(/[\"]/g, '\\"')
          .replace(/[\n]/g, "\\n")
          .replace(/[\r]/g, "\\r");
      },
      removeWhite: function (e) {
        e.normalize();
        for (var n = e.firstChild; n; ) {
          if (n.nodeType == 3) {
            // text node
            if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
              // pure whitespace text node
              var nxt = n.nextSibling;
              e.removeChild(n);
              n = nxt;
            } else n = n.nextSibling;
          } else if (n.nodeType == 1) {
            // element node
            X.removeWhite(n);
            n = n.nextSibling;
          } // any other node
          else n = n.nextSibling;
        }
        return e;
      },
    };
    if (xml.nodeType == 9)
      // document node
      xml = xml.documentElement;
    var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
    return (
      "{\n" +
      tab +
      (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) +
      "\n}"
    );
  }
  getObjects(obj, key, val) {
    var objects = [];
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] == "object") {
        objects = objects.concat(this.getObjects(obj[i], key, val));
      }
      //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
      //if (i == key && obj[i] == val || i == key && val == '') { //
      else if ((i == key && obj[i].match(val)) || (i == key && val == "")) {
        objects.push(obj);
        //} else if (obj[i] == val && key == ''){
      } else if (obj[i].match(val) && key == "") {
        //only add if the object is not already in the array
        if (objects.lastIndexOf(obj) == -1) {
          objects.push(obj);
        }
      }
    }
    return objects;
  }

  //return an array of values that match on a certain key
  getValues(obj, key) {
    var objects = [];
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] == "object") {
        objects = objects.concat(this.getValues(obj[i], key));
      } else if (i == key) {
        objects.push(obj[i]);
      }
    }
    return objects;
  }

  //return an array of keys that match on a certain value
  getKeys(obj, val) {
    var objects = [];
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] == "object") {
        objects = objects.concat(this.getKeys(obj[i], val));
      } else if (obj[i] == val) {
        objects.push(i);
      }
    }
    return objects;
  }

  exportexcelcorrectasdd() {
    /* table id is passed over here */
    const element = document.getElementById("facturasCorrectas");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, "facturascorrectas.xlsx");
  }

  exportexcelerroresdd() {
    /* table id is passed over here */
    const element = document.getElementById("facturasErrores");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, "facturaserrores.xlsx");
  }
}
