import { Component, OnInit } from '@angular/core';
import { FacturasService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-regfactcopachisa',
  templateUrl: './regfactcopachisa.component.html',
  styleUrls: ['./regfactcopachisa.component.css']
})
export class RegfactcopachisaComponent implements OnInit {
  idu: string;
  facturas: any[] = []
  cols: any[];
  selectedFac: any[];
  resulgood = [];
  resulerr = [];
  facterr = 0;
  factgood = 0;
  facterrsistema = 0;
  muestrareporfinal: boolean;
  ipdirection: any;
  constructor(private inv: FacturasService) { }

  ngOnInit() {
    swal2.showLoading();
    
    this.idu = localStorage.getItem('id');
    this.muestrareporfinal = false;
    this.selectedFac = [];
    this.inv.getInvoicescopachisa().subscribe(r => {this.facturas = r;
    console.log(this.facturas)});
    swal2.close();
    this.cols = [
      { field: 'emitter_rfc', header: 'RFC emisor' },
      { field: 'receiver_rfc', header: 'RFC receptor' },
      { field: 'invoice_serie', header: 'Serie' },
      { field: 'invoice_folio', header: 'Folio' },
      { field: 'uuid', header: 'UUID' },
      { field: 'total', header: 'Total' }
    ];
  }

  reset() {
    this.ngOnInit();
  }
  async registracopachisa() {
    swal2.showLoading();
    let autcopa = await (await this.inv.getListxdomain('AUTORIZATION_COPACHISA')).toPromise();
    if (autcopa.value === null) {
      swal2.fire('No se encontro parametro general AUTORIZATION_COPACHISA','','info');
      return
    }
    let urlcopa = await (await this.inv.getListxdomain('URL_COPACHISA')).toPromise();
    if (urlcopa.value === null) {
      swal2.fire('No se encontro parametro general URL_COPACHISA','','info');
      return
    }
    this.resulgood = [];
    this.resulerr = [];
    this.facterr = 0;
    this.facterrsistema = 0;
    this.factgood = 0;
    console.log(this.selectedFac)
    if (this.selectedFac.length > 0) {
      for await (const num of this.selectedFac) {
        let params = {
          uuid : num.uuid, 
          importe : num.total 
       } 
        try {
          let t = await this.inv.postInvoicescopachisa(params,autcopa.value,urlcopa.value).toPromise();
          this.factgood = this.factgood + 1;
          t.idf = num.id;
          this.resulgood.push(t);
        } catch (error) {
          let err = error.error;
          err.idf = num.id;
          this.facterr = this.facterr + 1;
          this.resulerr.push(err)
        }
      }
      if (this.resulgood.length > 0 ) {
        let ip = await (await this.inv.getIp()).toPromise();
        for await (const num of this.resulgood) {
          try {
            const t = await this.inv.postFacturaCopa(num.idf,this.idu, ip.ip).toPromise()
          } catch (error) {
            this.facterrsistema = this.facterrsistema + 1;
          }
        } 
      }
      console.log(this.idu)
      this.muestrareporfinal = true;
      swal2.close()
      console.log(this.resulerr);
    } else {
      swal2.fire('Debe seleccionar al menos una factura','','info')
    }
  }
  exportexcelcorrectas() {
    /* table id is passed over here */
    const element = document.getElementById('facturasCorrectas');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'facturascorrectas.xlsx');
  }
  
  exportexcelerrores() {
    /* table id is passed over here */
    const element = document.getElementById('facturasErrores');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'facturaserrores.xlsx');
  }

}
